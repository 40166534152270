import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "react-widgets/lib/scss/react-widgets.scss";

import { icons } from "./assets/icons";
import { StateProvider } from "states/ContextProvider";
import { setI18nConfig } from "i18n/config";

const { REACT_APP_DEV_COMPANY } = process.env;

setI18nConfig();

React.icons = icons;

if (!REACT_APP_DEV_COMPANY) {
  throw new Error(
    "Defina o valor de REACT_APP_DEV_COMPANY com loga, netsul, jnnet ou at3"
  );
}

require(`./themes/_at3/at3_style.scss`);

ReactDOM.render(
  <StateProvider>
    <App />
  </StateProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
